import React from 'react';
import Layout from '../components/Layout';
import ProductsBanner from '../components/ProductsBanner';
import VideoTextCard from '../components/VideoTextCard';
import ContentList from './pechub/ContentList';
import HomeLogos from '../components/HomeLogos';
import BlockIconText from '../components/BlockIconText';
import Banner from './pechub/assets/Banner.svg';
import Connector from './pechub/assets/Connector.svg';
import './pechub/index.scss'

export default () => (
  <Layout current="products" subCurrent="pechub">
    <ProductsBanner
      title="PEC 隐私增强计算平台"
      description="如何在精细化运营过程中利用更多维的外部数据能力，同时保障数据安全并确保合法合规？"
      description1="PEC提供开箱即用的多类外部数据源连接能力，并使用隐私计算技术能力确保双方数据安全。还可与Convertlab其他产品无缝集成，获得外部数据源的特征筛选、模型计算、效果提升的一体化体验，做到“数据可用不可见”。"
      img={Banner}
    />
    <VideoTextCard title="「业务型」外部数据价值的安全连接器" image={Connector} imgWidth={341} imgHeight={311}>
      <BlockIconText content="安全：信通院权威测评认证，安全的数据连接，安心地使用数据" color="blue" />
      <BlockIconText content="便捷：沉淀多年业务积累，场景数据+模型 开箱即用，跨产品无缝对接" color="green" />
      <BlockIconText content="有效：严格的三方数据准入标准，定期督查，确保数据实效性、可用性" color="blue" />
      <BlockIconText content="画像洞察：人群对比分析报告，洞察公域中的用户画像情况" color="green" />
      <BlockIconText content="特征增强：完善私域用户画像，基础信息+兴趣偏好+消费偏好" color="blue" />
      <BlockIconText content="模型预测：购买预测模型+线索评级模型+流失归因模型" color="green" />
    </VideoTextCard>
    <ContentList />
    <HomeLogos />
  </Layout>
);
